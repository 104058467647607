import React, { Component, Fragment } from 'react'
import Favourite from '../components/Favourite/Favourite'
import FooterDesktop from '../components/common/FooterDesktop'
import FooterMobile from '../components/common/FooterMobile'
import NavMenuDesktop from '../components/common/NavMenuDesktop'
import NavMenuMobile from '../components/common/NavMenuMobile'

class FavouritePage extends Component {
    componentDidMount(){
        window.scroll(0,0)
   }
  render() {
    return (
      <div>
        <Fragment> 
               <div className="Desktop">
                <NavMenuDesktop /> 
               </div>

               <div className="Mobile">
               <NavMenuMobile />  
               </div>                       

               <Favourite /> 

               <div className="Desktop">
               <FooterDesktop/>
               </div>

               <div className="Mobile">
               <FooterMobile/>
               </div>

          </Fragment>
      </div>
    )
  }
}

export default FavouritePage
